.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  padding: 20px 0;
  border-radius: var(--radius-md);
}

.left {
  flex-grow: 1;
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}