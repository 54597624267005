.wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  padding: 4px;
  background-color: #EDEDED;
  border-radius: var(--radius-sm);
  cursor: pointer;
  user-select: none;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 42px;
  border-radius: var(--radius-sm);
  transition: all .3s ease-in-out;

  &--checked {
    background-color: var(--background-green);
    color: var(--content-white);
  }
}
