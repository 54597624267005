.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 200px 0;
}

.title {
  font-weight: 800;
  font-size: 64px;
}

.description {
  font-size: 42px;
}

.message {
  font-size: 20px;
}
