.wrapper {
  width: 400px;
  max-width: 480px;
  padding: 32px;
  text-align: center;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: 0 8px 35px rgba(0, 0, 0, 0.15);

  img {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: var(--content-secondary);
  }

  a {
    display: block;
    padding: 15px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background-color: var(--btn-primary);
    color: var(--btn-primary-alt);
    border-radius: var(--radius-sm);
    cursor: pointer;
    transition: all .15s ease;

    &:hover {
      opacity: .9;
      transform: scale(1.01);
    }

    &:active {
      transform: scale(0.9995);
    }
  }
}