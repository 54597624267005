.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 255px;
  width: 100%;
  padding-top: 10px;
  background-color: var(--background-green);
}

.profile {
  margin-bottom: 20px;
  padding-left: 30px;
}

.menu {
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
  //
  //&::-webkit-scrollbar-track {
  //  //background: #f1f1f1;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: #18CDCD;
  //}
}

.link {
  display: block;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #93acc7;
  border-left: 5px solid var(--border-success);
  transition: all .3s ease;
}

.logout {
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 20px 30px;
  font-size: 18px;
  background-color: #f3f1f1;
  cursor: pointer;
}