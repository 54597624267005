.wrapper {
  display: block;

  &Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 124px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.4);
    border: 2px dashed #dbdbe7;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    user-select: none;
  }

  &--state {
    &-default {
      .wrapperInner {
        border-color: #dbdbe7;
      }
    }

    &-success {
      .wrapperInner {
        border-color: #30ad1b;
      }
    }

    &-error {
      .wrapperInner {
        border-color: #f61e2e;
      }

      .message {
        color: #f61e2e;
      }
    }

    &-uploaded {
      .wrapperInner {
        background-color: #ffffff;
      }
    }
  }

  &:hover {
    .wrapperInner {
      border-color: #2678f4;
    }

    .upload {
      &Icon {
        color: #2678f4;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.content {
  display: block;
  margin-right: 16px;
  overflow: hidden;
}

.title {
  margin-bottom: 10px;
  color: rgba(18, 18, 67, 0.3);
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
}

.name {
  margin-bottom: 10px;
  overflow: hidden;
  color: #161624;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.detail {
  &List {
    display: block;
  }

  &Item {
    color: rgba(18, 18, 67, 0.3);
    font-weight: 600;
    font-size: 11px;
    line-height: 140%;

    + .detailItem {
      margin-top: 4px;
    }
  }
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  padding: 0 8px;

  &Icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    margin-bottom: 12px;
    color: rgba(18, 18, 67, 0.3);
    transition: all 0.3s ease-in-out;
  }

  &Button {
    display: block;
    width: 100%;
    padding: 5px 8px;
    color: #161624;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    background: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;

    &:hover {
      color: #161624;
    }

    input {
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.preview {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  overflow: hidden;
  border-radius: 8px;

  &Image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: #ffffff;

    .file-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: rgba(18, 18, 67, 0.3);
      border-radius: 8px;
      &--file {
        background: linear-gradient(135deg, #e6fffd 0%, #a3deff 100%);
      }
      &--video {
        background: linear-gradient(135deg, #fbffe6 0%, #ffc4a3 100%);
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.21%, rgba(0, 0, 0, 0.35) 100%);
      content: '';
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Button {
    &List {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin: 0 8px;
      color: #161624;
      background-color: #ffffff;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

.progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &Header {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  &Title,
  &Percent {
    color: #161624;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
  }

  &Title {
    flex: 1;
    width: 100%;
  }

  &Percent {
    margin-left: 8px;
  }

  &Cancel {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-left: 8px;
    color: #161624;
    cursor: pointer;
    user-select: none;
  }

  &Bar {
    width: 100%;
    height: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 29px;

    &Inner {
      height: 10px;
      background: #2678f4;
      border-radius: 33px;
    }
  }

  &Info {
    color: rgba(18, 18, 67, 0.5);
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
  }
}

.message {
  margin-top: 6px;
  color: #161624;
  font-weight: 600;
  font-size: 11px;
  line-height: 140%;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
