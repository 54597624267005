.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--content-secondary);
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
    content: '';
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--checked {
    .icon {
      visibility: visible;
      opacity: 1;
    }
  }

  &:not(.wrapper--disabled):hover {
    &::before {
      border-color: var(--status-blue);
    }
  }

  &--variant {
    &-light {
      &::before {
        border-color: var(--content-primary-alt);
      }

      &:hover {
        &::before {
          border-color: var(--status-blue-light);
        }
      }

      .icon {
        color: var(--status-blue);
        background-color: var(--content-primary-alt);
      }
    }
  }
}

.icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  color: var(--content-white);
  background-color: var(--background-green);
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
