.wrapper {
  display: block;
  width: var(--size, 100%);
  height: var(--size, 100%);

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
