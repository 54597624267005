.action {
  display: flex;
  column-gap: 15px;

  svg {
    cursor: pointer;
    transition: all .15s ease;

    &:hover {
      opacity: .6;
    }
  }
}