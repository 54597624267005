.wrapper {
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  padding: 10px 15px;
  background-color: #E5E7EC;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    opacity: .9;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.9995);
  }
}

.name {
  color: #9394AB;
}

.value {
  display: -webkit-box;
  max-width: 400px;
  color: var(--content-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.icon {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--background-primary);
  transition: all .15s ease;

  &:hover {
    background-color: var(--background-green);

    svg path {
      fill: var(--content-white);
    }
  }

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: var(--content-green);
      transition: all .15s ease;
    }
  }
}