.tox-tinymce {
  border: 1px solid var(--border-primary) !important;
}

.tox {
  .tox-toolbar__primary {
    height: 37px;
    border-bottom: 1px solid var(--border-primary);
  }

  .tox-toolbar__group {
    border: none !important;
  }

  .tox-tbtn {
    color: var(--content-primary) !important;

    &:not(.tox-tbtn--disabled) svg {
      fill: var(--content-primary) !important;
    }

    &:not(.tox-tbtn--disabled):hover {
      background-color: unset !important;

      svg {
        fill: var(--content-primary) !important;
      }
    }
  }

  .tox-tbtn.tox-tbtn--enabled {
    background-color: unset !important;

    svg {
      fill: var(--content-primary) !important;
    }
  }

  .tox-collection__item-caret svg {
    fill: var(--content-primary) !important;
  }

  .tox-tbtn:focus {
    background-color: unset !important;
  }

  .tox-tbtn,
  .tox-swatch,
  .tox-tbtn__select-label {
    cursor: pointer !important;
  }

  .tox-tbtn svg {
    fill: var(--content-primary) !important;
  }

  .tox-collection--list .tox-collection__item--enabled {
    background-color: unset !important;
  }

  .tox-collection--list .tox-collection__item--active {
    background-color: unset !important;
  }

  .tox-dialog-wrap__backdrop {
    background-color: unset !important;
  }
}

.tox-tbtn.tox-tbtn--disabled {
  cursor: not-allowed !important;
}

