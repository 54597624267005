.list {
  min-width: 150px;
  margin: 0;
  padding: 5px 0;
  list-style: none;
  background-color: var(--background-primary);
  border-radius: var(--radius-md);
  box-shadow: 0 8px 35px rgba(0, 0, 0, 0.15);
}

.item {
  position: relative;
  padding: 10px 32px 10px 10px;
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    background-color: #e7f9ff;
  }

  &Icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: var(--background-green);
    border-radius: 50%;

    svg path {
      stroke: var(--content-white);
    }
  }
}

.title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--background-green);
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: var(--content-white);
    }
  }
}