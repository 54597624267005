.wrapper {
  display: block;
  font-size: 13px;
  line-height: 140%;

  &--state {
    &-default,
    &-success,
    &-error {
      .editor {
        box-shadow: none;
      }
    }

    &-default {
      .editor {
        border-color: transparent;
      }
    }

    &-success {
      .editor {
        //background-color: var(--status-green-light);
        //border-color: var(--status-green);
      }

      .input {
        &:-webkit-autofill {
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px var(--status-green-light);
        }

        &:hover,
        &:focus {
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px var(--surface-z-0);
        }
      }
    }

    &-error {
      .editor {
        border-color: red;
      }

      .message {
        color: red;
      }
    }
  }

  &:hover,
  &--focused {
    &:not(.wrapper--disabled) {
      .editor {
        //border-color: var(--border-primary);
      }
    }
  }

  &--focused {
    //.editor {
    //  box-shadow: var(--shadow-xs);
    //}
  }

  &--disabled {
    .editor {
      cursor: not-allowed;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.editor {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.message {
  font-size: 16px;
}
