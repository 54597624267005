.select {
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background-color: var(--background-primary);
  color: var(--content-primary);
  border: 1px solid transparent;
  border-radius: var(--radius-sm);

  &--size {
    &-sm {
      padding: 10px;
    }

    &-md {
      padding: 15px;
    }
  }

  &:hover,
  &--focused {
    //border-color: var(--border-success);
  }

  &--state {
    &-default {
      border-color: var(--border-primary);
    }

    &-success {
      border-color: var(--border-success);
    }

    &-error {
      border-color: var(--border-danger);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.message {
  color: var(--content-danger);
}
