.wrapper {
  position: relative;

  input {
    min-width: 200px;
  }
}

.content {
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 2001;
  visibility: hidden;
  opacity: 0;
  transition: all .15s ease;

  &--show {
    visibility: visible;
    opacity: 1;
  }
}