.label {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--content-black);

  span {
    color: var(--content-danger);
  }
}