.badge {
  display: inline-block;
  padding: 8px 10px;
  border-radius: var(--radius-sm);

  &--status-ACTIVE,
  &--status-FINISHED {
    background-color: #EDFAEB;
    color: #30AD1B;
  }

  &--status-WAITING {
    background-color: #FAEED0;
    color: #96723D;
  }

  &--status-CALLED {
    background-color: #FFE7E8;
    color: #F61E2E;
  }

  &--status-INACTIVE {
    background-color: #FFE7E8;
    color: #F61E2E;
  }
}