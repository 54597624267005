.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .3);
  transform: scale(0);
  transform-origin: center;
  visibility: hidden;
  transition: all .2s ease;

  &--open {
    transform: scale(1);
    visibility: visible;
  }
}

.content {
  position: relative;
  max-width: var(--max-width);
  width: 100%;
  padding: 30px;
  background-color: var(--background-primary);
  border-radius: var(--radius-lg);
  overflow: hidden;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  color: var(--content-secondary);
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: var(--content-danger);
  }
}