.rdt_TableRow {
  padding: 5px 0 !important;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.jVeafb {
  border-bottom: 1px solid var(--border-primary);
}

.eoCpxj,
.ezwUxc {
  padding: 5px 10px !important;
  border: none !important;
}

.eUeqdG {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
}

.rdt_TableCell {
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--content-secondary);
}