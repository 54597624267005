.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &Text,
  &Count {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    color: var(--content-primary);
    opacity: .8;
  }

  &Text {
    margin-right: 15px;
  }

  &Count {
    margin-right: 30px;
  }
}